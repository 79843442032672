<template>
    <b-card
        class="p-1"
        no-body
    >
        <b-form>
            <b-form-group>
                <b-form-file
                    v-model="file"
                />
            </b-form-group>
            <b-form-group>
                <b-button @click="onLoadFile"> Cargar pedimento </b-button>
            </b-form-group>
        </b-form>
    </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BButton,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import mbgeService from '@/services/mbge.service'

export default {
  components: {
    BCard,
    BForm,
    BButton,
    BFormGroup,
    BFormFile,
  },
  setup() {
    const file = ref(null)
    const {
      createPedimentoDocument,
    } = mbgeService()

    const onLoadFile = () => {
      if (file.value) {
        const formData = new FormData()
        formData.append('pedimentoId', router.currentRoute.params.pedimentoId)
        formData.append('operacionDocumentoTipo', 1)
        formData.append('archivo', file.value)
        // console.log(formData)
        createPedimentoDocument(formData, () => {
          router.push({ name: 'apps-transfers-mbge-pedimento-list' })
          // console.log(formData)
        })
      }
    }

    return {
      file,
      onLoadFile,
    }
  },
}
</script>

<style>

</style>
